const hallPoints = {
    "1": [
        {x: 0, y: 508},
        {x: 73, y: 489},
        {x: 138, y: 499},
        {x: 183, y: 512},
        {x: 239, y: 489},
        {x: 243, y: 437},
        {x: 200, y: 411},
        {x: 147, y: 412},
        {x: 91, y: 422},
        {x: 66, y: 343},
        {x: 67, y: 300},
        {x: 73, y: 243},
        {x: 69, y: 171},
        {x: 88, y: 128},
        {x: 96, y: 69},
        {x: 132, y: 51},
        {x: 176, y: 61},
        {x: 218, y: 61},
        {x: 282, y: 64},
        {x: 321, y: 63},
        {x: 339, y: 63},
        {x: 339, y: 118},
        {x: 334, y: 163},
        {x: 328, y: 214},
        {x: 318, y: 264},
        {x: 323, y: 309},
        {x: 357, y: 338},
        {x: 400, y: 338},
        {x: 400, y: 0},
        {x: 0, y: 0}
      ],
    "2": [
        {x: 0, y: 548},
        {x: 68, y: 532},
        {x: 68, y: 467},
        {x: 78, y: 392},
        {x: 68, y: 321},
        {x: 141, y: 233},
        {x: 162, y: 152},
        {x: 108, y: 89},
        {x: 54, y: 72},
        {x: 53, y: 0},
        {x: 400, y: 0},
        {x: 400, y: 63},
        {x: 346, y: 44},
        {x: 303, y: 44},
        {x: 273, y: 56},
        {x: 243, y: 88},
        {x: 252, y: 123},
        {x: 313, y: 157},
        {x: 352, y: 159},
        {x: 343, y: 227},
        {x: 352, y: 293},
        {x: 342, y: 351},
        {x: 318, y: 418},
        {x: 318, y: 468},
        {x: 329, y: 523},
        {x: 400, y: 529},
        {x: 400, y: 0},
        {x: 0, y: 0}
      ],
    "3": [
        {x: 73, y: 211},
        {x: 94, y: 154},
        {x: 176, y: 138},
        {x: 253, y: 136},
        {x: 343, y: 148},
        {x: 352, y: 222},
        {x: 339, y: 276},
        {x: 263, y: 272},
        {x: 178, y: 284},
        {x: 94, y: 269}
      ],
  };

  export default hallPoints;