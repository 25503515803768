import BoulderHall from "./BoulderHall";
import HallSelector from "./HallSelector";
import { useState } from "react";
import { useQuery } from '@tanstack/react-query';
import './AppWrapper.css';
import AddWidget from "./AddWidget";
import SelectedRouteWidget from "./SelectedRouteWidget";
import AddButton from "./AddButton";
import hallPoints from "./HallPolygons";
import { useSwipeable } from "react-swipeable";

const backendHost = window.APP_CONFIG.apiUrl;

function AppWrapper() {
    const [selectedHall, setSelectedHall] = useState(1);
    const [addMode, setAddMode] = useState(false);
    const [newRoute, setNewRoute] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);

    

    const { hallsPending, data: halls} = useQuery({
        queryKey: ['hallData'],
        queryFn: () =>
          fetch(`${backendHost}/api/halls`).then((res) =>
            res.json(),
          ),
      });

      const swipeHandlers = useSwipeable({
        onSwipedLeft: () => { 
            if(!addMode && halls != null && halls.find(h => h.id === selectedHall + 1))
                setSelectedHall(selectedHall + 1)
        },
        onSwipedRight: () => { 
            if(!addMode && halls != null && halls.find(h => h.id === selectedHall - 1))
                setSelectedHall(selectedHall - 1) 
            },
      });

    const { isPending, data: routes, refetch } = useQuery({
        queryKey: ['routeData', selectedHall],
        queryFn: () =>
          fetch(`${backendHost}/api/halls/${selectedHall}/routes`).then((res) =>
            res.json(),
          ),
      })

    if (hallsPending || isPending) return (<div>Loading</div>);

    const updateRoute = (position) => {
        const updatedRoute = {
            ...newRoute,
            x: position.x,
            y: position.y
        };
        setNewRoute(updatedRoute);
    }

    const enableAddMode = (e) => {
        setAddMode(true);
        setNewRoute({
            x: 200, y: 200, color: 'red', level: 5, isNew: true
        });
    };

    const disableAddMode = (e) => {
        setAddMode(false);
        setNewRoute(null);
    }

    const changeColor = (color) => {
        const changedRoute = {
            ...newRoute,
            color: color
        };
        setNewRoute(changedRoute);
    }

    const changeLevel = (level) => {
        const changedRoute = {
            ...newRoute,
            level: level
        };
        setNewRoute(changedRoute);
    }

    const onSaveRoute = (e) => {
        fetch(`${backendHost}/api/halls/${selectedHall}/routes`, {
            method: 'POST', // Methode fest
            body: JSON.stringify(newRoute),
            headers: {
                "Content-Type": "application/json",
              }
        }).then(() => refetch());
        setNewRoute(null);
        setAddMode(false);
    };

    const onDeleteRoute = (routeId) => {
        fetch(`${backendHost}/api/halls/${selectedHall}/routes/${routeId}`, {
            method: 'DELETE'
        }).then(() => refetch());
        setSelectedRoute(null);
    };

    const routeClicked = (id) => {
        setSelectedRoute(id);
    };

    const onSelectClose = (e) => {
        setSelectedRoute(null);
    };

    const onHallChanged = (hallId) => {
        setSelectedHall(hallId);
    }

    return (
        <div className="wrapper" {...swipeHandlers}>
            <HallSelector halls={halls} onSelectHall={onHallChanged} selectedHall={selectedHall} />
            <BoulderHall                
                hallPoints={hallPoints[selectedHall]} 
                routes={routes} 
                newRoute={newRoute}
                updatePosition={updateRoute}
                routeClicked={routeClicked}
                selectedRoute={selectedRoute} />
            <AddButton onAdd={enableAddMode} />
            <AddWidget 
                addMode={addMode} 
                onAdd={enableAddMode}
                onSave={onSaveRoute}
                onClose={disableAddMode}
                changeColor={changeColor}
                changeLevel={changeLevel} />
            <SelectedRouteWidget
                route={routes.find( (r) => r.id === selectedRoute)} 
                open={selectedRoute != null} 
                onClose={onSelectClose}
                onDelete={onDeleteRoute} />
        </div>
    )
};

export default AppWrapper;