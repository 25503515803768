

const colors = {
    "red": {
        background: "#E57373",
        border: "#D32F2F",
        font: "#000000"
    },
    "green": {
        background: "#81C784",
        border: "#388E3C",
        font: "#000000"
    },
    "blue": {
        background: "#64B5F6",
        border: "#1976D2",
        font: "#000000"
    },
    "yellow": {
        background: "#FFF176",
        border: "#FBC02D",
        font: "#000000"
    },
    "orange": {
        background: "#FFB74D",
        border: "#F57C00",
        font: "#000000"
    },
    "purple": {
        background: "#BA68C8",
        border: "#8E24AA",
        font: "#000000"
    },
    "white": {
        "background": "#FFFFFF",
        "border": "#E0E0E0",  // Ein sehr helles Grau für sanfte Kanten
        "font": "#000000"  // Schwarz für bestmögliche Lesbarkeit auf weißem Hintergrund
    },
    "black": {
        "background": "#000000",
        "border": "#424242",  // Ein dunkles Grau für subtile Abgrenzung
        "font": "#FFFFFF"  // Weiß für starken Kontrast und Lesbarkeit
    },
    "pink": {
        "background": "#F8BBD0",  // Helleres Pink
        "border": "#EC407A",  // Ein etwas dunkleres Pink für den Rand
        "font": "#000000"  // Weiß für guten Kontrast
    },
    "mint": {
        "background": "#B2EBF2",  // Brighter mint blue (light cyan with a hint of blue)
        "border": "#4DD0E1",      // Brighter cyan for the border
        "font": "#000000"         // Black font for readability
    }
};

export default colors;