import './AddButton.css';

function AddButton({onAdd}) {
    return (
        <div className="add-button">
            <button onClick={onAdd}>+</button>
        </div>
    )
}

export default AddButton;