import { useState } from 'react';
import './HallSelector.css'
import ScoreBoard from './ScoreBoard';

function HallSelector({halls, onSelectHall, selectedHall}) {

    const [showOptions, setShowOptions] = useState(false);
    const [showScoreboard, setShowScoreboard] = useState(false);

    const toggleShow = () => setShowOptions(!showOptions);
    const toggleShowScoreboard = () => setShowScoreboard(!showScoreboard);

    const hallName = halls != null ? halls.find(h => h.id === selectedHall).name : "Loading";
    
    return (
        <div className='hall-selector'>
            <div className="buttons">
                <div className="current-hall" onClick={toggleShow}>{hallName}</div>
                <div className="scores-button" onClick={toggleShowScoreboard}>Scores</div>
            </div>
            <div className={"hall-options" + (showOptions ? ' open' : '')}>
                {halls.map(hall => (
                    <span onClick={() => {onSelectHall(hall.id); toggleShow();}} className="hall-option">{hall.name}</span>
                ))}
            </div>
            <div className={"scoreboard" + (showScoreboard ? ' open': '')}>
                <ScoreBoard open={showScoreboard} />
            </div>
        </div>
    )
};

export default HallSelector;