import { useQuery } from "@tanstack/react-query";
import './ScoreBoard.css';
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const backendHost = window.APP_CONFIG.apiUrl;

function ScoreBoard(open) {

    const [selectedDate, setSelectedDate] = useState(new Date());

    const nextMonth = () => {
        setSelectedDate(new Date(selectedDate.setMonth(selectedDate.getMonth() + 1)));
    }

    const prevMonth = () => {
        setSelectedDate(new Date(selectedDate.setMonth(selectedDate.getMonth() - 1)));
    }

    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;

    const { isPending, data: scores} = useQuery({
        queryKey: ['scoreData', year, month,open],
        queryFn: () =>
          fetch(`${backendHost}/api/scoreboard/${year}/${month}`).then((res) =>
            res.json(),
          ),
      });
  return (
    (<div class="scoreboard-wrapper">
        {isPending && <div>Loading...</div>}
        <div class="scoreboard-header">
            <button onClick={prevMonth}><FontAwesomeIcon icon="caret-left" /></button>
            <h2>Scoreboard {month}.{year}</h2>
            <button onClick={nextMonth}><FontAwesomeIcon icon="caret-right" /></button>
        </div>
        {scores && <div class="scoreboard-content">
            {scores.map((score, index) => (<div class="scoreboard-row">
                <span class="player">{score.person}</span>
                <span class="score">{score.done}</span>
            </div>))}
        </div>}
    </div>)
    
  );
}

export default ScoreBoard;