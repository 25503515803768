import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import './AddWidget.css';

function AddWidget({addMode, onAdd, onSave, onClose, changeColor, changeLevel}) {

    const [level, setLevel] = useState(5);
    const [color, setColor] = useState(0);

    const colors = [
        "red","green", "blue", "yellow", "orange", "purple", "white", "black", "pink", "mint"
    ];
    
    const nextColor = () => {
        const newColor =  (color + 1) % colors.length;
        setColor(newColor); 
        changeColor(colors[newColor]);
    }

    const incLevel = (inc) => {
        const newLevel = Math.max(0, Math.min(level + inc, 9));
        setLevel(newLevel);
        changeLevel(newLevel);
    }

    return (
        <div className={`addWidget ${addMode ? 'open' : ''}`}>
            <div className="innerWidget ">
                <button onClick={(e) => {onClose(e)}}><FontAwesomeIcon icon="xmark" /></button> 
                <button onClick={() => incLevel(-1)}><FontAwesomeIcon icon="caret-down" /></button>
                <div onClick={nextColor} className={`circle ${colors[color]}`}>
                    {level}
                </div>
                <button onClick={() => incLevel(1)}><FontAwesomeIcon icon="caret-up" /></button>
                <button onClick={(e) => {onSave(e); setColor(0); setLevel(5);}}><FontAwesomeIcon icon="check" /></button>
            </div>
        </div>
    )
}

export default AddWidget;