import React from 'react';
import { Circle, Text, Group } from 'react-konva';
import colors from './Colors';

function BoulderRoute({ id, x, y, color, level, isNew, selected, updatePosition, routeClicked }) {

  const routeColor = colors[color];

  return (
    <Group
        x={x}
        y={y}
        draggable={isNew}
        onDragEnd={(e) => {
          updatePosition(e.target.position());
        }}
        onClick={(e) => routeClicked ? routeClicked(id) : {}}
        onTap={(e) => routeClicked ? routeClicked(id) : {}}
    >
      <Circle
        x={0}
        y={0}
        radius={15}
        fill={routeColor.background}
        hitStrokeWidth={50}
        stroke={selected ? "yellow" : routeColor.border}
      />
      
      <Text
        x={-10}
        y={-6}
        width={10 * 2}
        text={level}
        fontSize={16}
        fontStyle="bold"
        align="center"
        verticalAlign="middle"
        fill={routeColor.font}
      />
    </Group>
  );
}

export default BoulderRoute;