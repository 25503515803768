import React from 'react';
import { Stage, Layer, Rect, Line, Group } from 'react-konva';
import BoulderRoute from './BoulderRoute';

const BoulderHall = ({ hallPoints, routes, newRoute, updatePosition, routeClicked, selectedRoute }) => {
    
    const foreground = '#2C3E50';
    const background = "#ECF0F1";
    const lines = "#34495E";
    const strokeWidth = 1;
    
  return (
    <div>
    <Stage width={400} height={600}>
      <Layer>
      <Group>
        <Rect width={400}
        height={800}  fill={background} />
        <Line
            points={hallPoints.flatMap(point => [point.x, point.y])}
            stroke={lines}
            fill={foreground}
            strokeWidth={strokeWidth}
            closed
        />
        {routes.map(route => (
          <BoulderRoute 
            key={route.id} 
            {...route} 
            selected={route.id === selectedRoute}
            routeClicked={routeClicked}  />
        ))}
        {newRoute && (
          <BoulderRoute 
            key={newRoute.id} 
            {...newRoute} 
            updatePosition={updatePosition} />
        )}
        </Group>
      </Layer>
    </Stage>
    </div>
  );
}

export default BoulderHall;